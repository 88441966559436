export default function shopFilters() {
    const filterShowButton = document.getElementById('shop-filters-show');
    const filterSelect = document.getElementById('shop-filters-select');
    const filterSelectToggle = document.getElementById(
        'shop-filters-select-toggle'
    );
    const filterSelectDropdown = document.getElementById(
        'shop-filters-select-dropdown'
    );
    const hideFiltersButton = document.querySelector('.js-close-filters');
    const shopFilters = document.getElementById('shop-filters');
    const resetFiltersButton = document.querySelector(
        '.shop-filters__reset-filters'
    );
    const shapeAllButton = document.querySelector('.js-shop-filter-shape-all');

    const selectedCategoryOption = jQuery('#select2-categories')[0]
        ? jQuery('#select2-categories :selected')[0].value
        : false;

    if (filterShowButton)
        filterShowButton.addEventListener('click', e => {
            if (shopFilters) shopFilters.classList.add('active');
        });

    if (hideFiltersButton) {
        hideFiltersButton.addEventListener('click', e => {
            if (shopFilters) shopFilters.classList.remove('active');
        });
    }

    if (resetFiltersButton) {
        resetFiltersButton.addEventListener('click', () => {
            shapeAllButton.checked = false;
            const categoryAll = shopFilters.querySelector('#category_all');
            categoryAll.checked = true;
            resetShapeFilters();
            resetSelectFilters('');
            jQuery('#select2-sorting')[0][0].selected = 'selected';
            jQuery('#select2-sorting').trigger('change');
            makeAjaxRequest(
                false,
                false,
                shopFilters.querySelector('#category_all').value
            );
        });
    }

    if (filterSelectToggle)
        filterSelectToggle.addEventListener('click', e => {
            filterSelect.classList.toggle('active');
        });

    document.body.addEventListener('change', function (e) {
        const categoriesSelect = jQuery('#select2-categories');
        const target = e.target;
        const { value } = target;
        const select2CategoriesVal =
            categoriesSelect && categoriesSelect.length
                ? categoriesSelect.select2('val')
                : null;
        if (target.matches('.js-shop-filter')) {
            const toggles = document.querySelectorAll(
                `.js-shop-filter[value="${value}"]`
            );
            let catValueToFetch = '';

            if (!target.value) {
                catValueToFetch = select2CategoriesVal;
            } else {
                catValueToFetch = selectedCategoryOption;
            }
            toggles.forEach(item => (item.checked = true));
            resetSelectFilters(value);
            resetShapeFilters();
            makeAjaxRequest(false, false, catValueToFetch);
        } else if (target.matches('.js-shop-filter-shape')) {
            shapeAllButton.checked = false;
            makeAjaxRequest(true, false, select2CategoriesVal);
        } else if (target.matches('.js-shop-filter-shape-all')) {
            resetShapeFilters();
            makeAjaxRequest(true, false, select2CategoriesVal);
        }
    });

    document.body.addEventListener('click', function (e) {
        if (
            e.target.matches('.js-change-page') &&
            !e.target.hasAttribute('data-total')
        ) {
            e.preventDefault();

            document.querySelectorAll('.js-change-page').forEach(el => {
                el.classList.remove('is-current');
            });

            e.target.classList.add('is-current');

            const selectedCategoryOption = jQuery('#select2-categories')[0]
                ? jQuery('#select2-categories :selected')[0].value
                : false;
            makeAjaxRequest(true, false, selectedCategoryOption);
        }
    });

    jQuery(document).ready(function () {
        jQuery('#select2-sorting').on('select2:select', function (e) {
            const selectedOption = e.params.data.id;
            if (selectedOption) {
                makeAjaxRequest(true, selectedOption);
            }
        });
        jQuery('#select2-categories').on('select2:select', function (e) {
            const selectedOption = e.params.data.id;
            if (selectedOption) {
                resetShapeFilters();
                makeAjaxRequest(false, false, selectedOption, true, true);
            }
        });
    });

    function makeAjaxRequest(
        shape = false,
        selectedOption = false,
        catId = false,
        changeCategories = false,
        forceSelectValueToCat = false
    ) {
        let formData = new FormData();

        if (selectedOption) {
            formData.append('orderby', selectedOption);
        }

        const form = document.querySelector('#shop-filters');

        if (form) {
            const form_data = new FormData(form);
            for (let [key, value] of form_data.entries()) {
                if (
                    forceSelectValueToCat &&
                    !formData.has('product_cat') &&
                    key === 'product_cat'
                ) {
                    formData.append('product_cat', catId);
                } else if (
                    key === 'product_cat' &&
                    !value &&
                    catId &&
                    !formData.has('product_cat')
                ) {
                    formData.append('product_cat', catId);
                } else {
                    formData.append(key, value);
                }
            }
        }

        const currentPage = document.querySelector('.is-current');
        if (currentPage) {
            const currentPageValue = currentPage.getAttribute('data-page');
            formData.append('paged', currentPageValue ?? 1);
        }

        formData.append('action', 'mda_shop_filters');

        sendAjaxRequest(formData, shape, changeCategories);
    }

    function sendAjaxRequest(
        formData,
        shape = false,
        changeCategories = false
    ) {
        const preloader = document.querySelectorAll('.preloader');
        var xhr = new XMLHttpRequest();

        xhr.open('POST', ajax.url);

        xhr.onloadstart = function () {
            preloader.forEach(item => item.classList.add('-active'));

            let shopFiltersSection = document.querySelector('.category-header');

            if (shopFiltersSection) {
                shopFiltersSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                try {
                    const response = JSON.parse(xhr.responseText);

                    if (response.products) {
                        const productsContainer =
                            document.querySelector('#products_list');
                        if (productsContainer) {
                            productsContainer.innerHTML = response.products;
                        }
                    }
                    if (!shape) {
                        const filtersContainer =
                            document.querySelector('#shape_filters');
                        if (response.filters) {
                            if (filtersContainer) {
                                filtersContainer.innerHTML = response.filters;
                            }
                        } else {
                            if (filtersContainer) {
                                filtersContainer.innerHTML = '';
                            }
                        }
                    }

                    if (changeCategories) {
                        const filtersContainer = document.querySelector(
                            '#filters-categories'
                        );

                        if (response.categories_filters) {
                            filtersContainer.innerHTML =
                                response.categories_filters;
                        }
                    }

                    if (response.pagination) {
                        const paginationContainer = document.querySelector(
                            '#pagination_container'
                        );
                        if (paginationContainer) {
                            paginationContainer.innerHTML = response.pagination;
                        }
                    }

                    if(response.trustmate){

                        $.ajax({
                            url: ajax.url,
                            type: 'POST',
                            data: {
                                action: 'load_trusmate_after_reload_products'
                            },
                            success: function(response) {
                                if(response.script){

                                var scripts = document.getElementsByTagName('script');

                                for (var i = 0; i < scripts.length; i++) {
                                    if (scripts[i].textContent.includes('insertHornets')) {
                                        scripts[i].remove();
                                        break;
                                    }
                                }

                                var elementTrustmate = document.getElementById('tm-widget-hornet');
                                
                                if (elementTrustmate) {
                                    elementTrustmate.remove();
                                }

                                $('body').append(response.script);
                                      
                                } 
                            },
                            error: function(xhr, status, error) {
                                console.error('Błąd AJAX: ', status, error);
                            }
                        });
                    }


                } catch (error) {
                    console.error('Nieprawidłowy format odpowiedzi:', error);
                }
            } else {
                console.log('Błąd zapytania: ' + xhr.status);
            }
        };

        xhr.onloadend = function () {
            preloader.forEach(item => item.classList.remove('-active'));
        };

        xhr.send(formData);
    }

    function resetSelectFilters(value) {
        const currentFilters = filterSelectToggle.querySelectorAll(
            '.shop-filters__filter'
        );
        const dropdownFilters = filterSelectDropdown.querySelectorAll(
            '.shop-filters__filter'
        );

        currentFilters.forEach(item => {
            item.classList.remove('current');
            item.classList.remove('hidden');
            const val = item.getAttribute('data-select-filter');
            if (val === value) {
                item.classList.add('current');
            } else {
                item.classList.add('hidden');
            }
        });

        dropdownFilters.forEach(item => {
            item.classList.remove('current');
            item.classList.remove('hidden');
            const val = item.getAttribute('data-select-filter');

            if (val === value) item.classList.add('hidden');
        });
    }

    function resetShapeFilters() {
        document.querySelectorAll('.js-shop-filter-shape').forEach(input => {
            input.checked = false;
        });
    }
}
