class HeaderMobile {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.header = document.querySelector('.header');
        this.toggle = document.querySelector('.header-icons__toggleMenu');
        this.close = this.root.querySelector('[data-header-mobile-close]');
        this.toggleSub = this.root.querySelectorAll(
            '[data-header-menu-toggle-sub]'
        );
        this.subItems = this.root.querySelectorAll(
            '[data-header-menu-subitem]'
        );
        this.backButton = this.root.querySelectorAll(
            '[data-header-mobile-back]'
        );

        this.prevSubItem = null;
        this.activeSubitem = null;
        this.headerHeight = null;
        this.oldScroll = 0;
        return true;
    }

    init() {
        this.initToggle();
        this.initClose();
        this.initSub();
        this.initBackButtons();
        this.handleHeight();
        this.handleMobileScroll();
    }

    initToggle() {
        if (!this.toggle) return;

        this.onToggle = this.onToggleClick.bind(this);
        this.toggle.addEventListener('click', this.onToggle);
    }

    initClose() {
        if (!this.close) return;

        this.onClose = this.onCloseClick.bind(this);
        this.close.addEventListener('click', this.onClose);
    }

    initSub() {
        if (!this.toggleSub) return;

        this.onToggleSub = this.onToggleSubClick.bind(this);
        this.toggleSub.forEach(item => {
            item.addEventListener('click', this.onToggleSub);
        });
    }

    initBackButtons() {
        if (!this.backButton) return;

        this.onBackClick = this.onSubCloseClick.bind(this);
        this.backButton.forEach(item => {
            item.addEventListener('click', event => this.onBackClick(event));
        });
    }

    handleHeight() {
        if (!this.header) return;

        this.headerHeight = this.header.offsetHeight;

        window.addEventListener('resize', () => {
            this.headerHeight = this.header.offsetHeight;
        });
    }

    handleMobileScroll() {
        window.addEventListener('scroll', () =>
            this.handleMobileStyles(this.header)
        );
    }

    onToggleClick(event) {
        this.root.classList.toggle('active');
        document.documentElement.classList.add('block-scroll');
    }

    onCloseClick(event) {
        this.root.classList.remove('active');
        document.documentElement.classList.remove('block-scroll');
    }

    onToggleSubClick(event) {
        event.preventDefault();

        const { currentTarget } = event;
        const id = currentTarget.getAttribute('data-header-menu-toggle-sub');
        const sub = this.root.querySelector(
            `[data-header-menu-subitem="${id}"]`
        );

        if (!sub) return;
        sub.classList.add('active');
        this.activeSubitem = sub;
    }

    onSubCloseClick(event) {
        if (!this.subItems) return;
        this.subItems.forEach(item => item.classList.remove('active'));

        if (
            this.activeSubitem &&
            this.activeSubitem.classList.contains(
                'header-mobile__subitems--nested'
            )
        ) {
            const parentSub = this.activeSubitem.closest(
                '[data-header-menu-subitem]:not(.header-mobile__subitems--nested)'
            );
            if (parentSub) {
                this.activeSubitem = parentSub;
                this.activeSubitem.classList.add('active');
            }
        }
    }

    handleMobileStyles(header) {
        const scrollOffset = window.scrollY;
        if (scrollOffset > 96) {
            if (this.oldScroll > scrollY) {
                header.classList.remove('hide-header');
            } else {
                header.classList.add('hide-header');
            }
        } else {
            header.classList.remove('hide-header');
        }
        this.oldScroll = scrollOffset;
    }
}

const setHeaderMobile = () => {
    const header = document.querySelector('[data-header-mobile]');
    if (header) new HeaderMobile(header);
};

export default setHeaderMobile;
