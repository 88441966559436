export default function catalogOrdering() {
    jQuery(document).ready(function ($) {
        if (jQuery.fn.select2) {
            jQuery('#select2-sorting').select2({
                minimumResultsForSearch: -1,
                width: 274,
            });

            jQuery('#select2-categories').select2({
                minimumResultsForSearch: -1,
                width: 274,
            });

            jQuery('#select2-back-to-cat').select2({
                minimumResultsForSearch: -1,
                width: 274,
            });

            jQuery('#select2-account-navigation').select2({
                minimumResultsForSearch: -1,
                containerCssClass: 'account-navigation',
            });

            if ($('#select2-account-navigation').length) {
                $('#select2-account-navigation')
                    .data('select2')
                    .$container.addClass('select2-account-navigation');
            }

            function toggleArrow(eventType, element) {
                var method =
                    eventType === 'select2:opening'
                        ? 'addClass'
                        : 'removeClass';
                $(element)
                    .parent()
                    .find('.select2-selection__arrow')
                    [method]('arrow-up');
            }

            $('#select2-account-navigation, #select2-sorting').on(
                'select2:opening select2:closing',
                function (e) {
                    toggleArrow(e.type, this);
                }
            );
        }
    });
}
