import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

export default function instagramSection() {
    const instagramSection = document.querySelector('section.instagram');
    if (!instagramSection) return;

    const preloader = instagramSection.querySelector('.preloader');
    preloader.classList.add('-active');

    const initSwiper = () => {
        const swiper = new Swiper('.js-instagram-slider', {
            modules: [Navigation, Scrollbar],
            spaceBetween: 8,
            slidesPerView: 1,
            scrollbar: {
                el: '.instagram__scrollbar',
                draggable: false,
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 1.1,
                },
                320: {
                    slidesPerView: 0.5,
                },
            },
        });
    };

    const handleLoadInstagram = async () => {
        try {
            const responseWrapper = document.querySelector(
                '.instagram__response-wrapper'
            );
            const data = new FormData();
            data.append('action', 'load_instagram_posts');
            const response = await fetch(ajax.url, {
                method: 'POST',
                body: data,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const res = await response.json();
            responseWrapper.innerHTML = res.data.html;
            preloader.classList.remove('-active');
            initSwiper();
        } catch (error) {
            console.log(error);
        }
    };

    window.addEventListener('load', () => {
        setTimeout(() => {
            handleLoadInstagram();
        }, 3000);
    });
}
