export default function articleShare() {
    (function ($) {
        const formClass = '.js-copy-link';
        const formFieldClass = '.js-copy-link-field';

        $('body').on('click', formClass, function (e) {
            e.preventDefault();
            let inputValue = $(this).find(formFieldClass).val();

            copyTextToClipboard(inputValue);
        });

        var share_trigger = '.js-share-page';

        $('body').on('click', share_trigger, function (e) {
            e.preventDefault();
            var $that = $(this),
                type = $that.attr('data-type');

            switch (type) {
                case 'facebook':
                    facebook_share($that);
                    break;
                case 'twitter':
                    twitter_share($that);
                    break;
                case 'linkedin':
                    linkedin_share($that);
                    break;
            }
        });

        function facebook_share(elem) {
            window.open(
                'https://www.facebook.com/sharer/sharer.php?u=' +
                    elem.attr('data-url'),
                'facebook-share-dialog',
                'width=626, height=436'
            );
        }

        function twitter_share(elem) {
            window.open(
                'http://twitter.com/share?text=' + elem.attr('data-url'),
                'facebook-share-dialog',
                'width=626, height=436'
            );
        }

        function linkedin_share(elem) {
            window.open(
                'https://www.linkedin.com/shareArticle?mini=true&url=' +
                    elem.attr('data-url'),
                'facebook-share-dialog',
                'width=626, height=436'
            );
        }

        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement('textarea');
            textArea.value = text;

            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }

        function copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(
                function () {
                    // console.log('Async: Copying to clipboard was successful!');
                },
                function (err) {
                    // console.error('Async: Could not copy text: ', err);
                }
            );
        }
    })(window.jQuery);
}
