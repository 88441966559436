export default function reviewOrderMore(){
    jQuery(document).ready(function($) {
        $(document).on('click', '.js-more-details', function() {

          var $this = $(this);
          var $details = $this.prev('.js-more-details-text');
          var isExpanded = $details.is(':visible');
          
          var expandText = $this.data('expand-text');
          var collapseText = $this.data('collapse-text');
          
          $details.slideToggle(500, function() {

            $this.find('span').remove();
            $this.fadeOut(function() {

                $(this).text(isExpanded ? expandText : collapseText)
                     .append('<span class="icon ' + (isExpanded ? 'icon-chevron-more' : 'icon-chevron-more') + '"></span>')
                     .fadeIn();

                     isExpanded ? $this.removeClass('is-expanded') : $this.addClass('is-expanded')
                       
            });
          });
        });
      });
}