export default function checkoutCountry() {
    jQuery(document).ready(function ($) {
        if (jQuery.fn.select2) {
            jQuery('.billing_country-select').select2({
                minimumResultsForSearch: -1,
                containerCssClass: 'billing_country-select2',
            });
        }
    });
}
