export default function blogArticles() {
    document.body.addEventListener('click', function (e) {
        const target = e.target;

        const formData = new FormData();

        if (
            target.matches('.js-articles-pagination') &&
            !e.target.hasAttribute('data-total')
        ) {
            e.preventDefault();
            const pageNumber = parseInt(target.getAttribute('data-page'));
            formData.append('paged', pageNumber ?? 1);

            formData.append('action', 'mda_get_articles');
            sendAjaxRequest(formData);
        }
    });

    function sendAjaxRequest(formData) {
        const preloader = document.querySelector('.preloader');
        var xhr = new XMLHttpRequest();

        xhr.open('POST', ajax.url);

        xhr.onloadstart = function () {
            if (preloader) {
                preloader.classList.add('-active');
            }

            let shopFiltersSection = document.querySelector('.category-header');

            if (shopFiltersSection) {
                shopFiltersSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                try {
                    const response = JSON.parse(xhr.responseText);

                    if (response.articles) {
                        const articlesContainer =
                            document.querySelector('#articles_list');
                        if (articlesContainer) {
                            articlesContainer.innerHTML = response.articles;
                        }
                    }

                    if (response.pagination) {
                        const paginationContainer = document.querySelector(
                            '#pagination_container'
                        );
                        if (paginationContainer) {
                            paginationContainer.innerHTML = response.pagination;
                        }
                    }
                } catch (error) {
                    console.error('Nieprawidłowy format odpowiedzi:', error);
                }
            } else {
                console.log('Błąd zapytania: ' + xhr.status);
            }
        };

        xhr.onloadend = function () {
            if (preloader) {
                preloader.classList.remove('-active');
            }
        };

        xhr.send(formData);
    }
}
