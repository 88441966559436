export const formCoupon = () => {
    const formCoupon = document.querySelector('.form-coupon');
    if (!formCoupon) return;

    const overlay = formCoupon.querySelector('.form-coupon__overlay');
    const closeButtonWrapper = formCoupon.querySelector(
        '.form-coupon__close-wrapper'
    );

    const closeFormCoupon = () => {
        formCoupon.classList.remove('active');

        const woocommerceError = formCoupon.querySelector('.woocommerce-error');

        if (woocommerceError) {
            setTimeout(() => {
                woocommerceError.remove();
            }, 300);
        }
    };

    const openFormCoupon = () => {
        formCoupon.classList.add('active');
    };

    overlay.addEventListener('click', closeFormCoupon);
    closeButtonWrapper.addEventListener('click', closeFormCoupon);

    document.addEventListener('click', e => {
        if (e.target.classList.contains('js-open-coupon-modal'))
            openFormCoupon();
    });

    $('body').on('applied_coupon_in_checkout', function (event, coupon) {
        const woocommerceMessage = formCoupon.querySelector(
            '.woocommerce-message'
        );
        setTimeout(() => {
            if (woocommerceMessage) {
                closeFormCoupon();
                setTimeout(() => {
                    woocommerceMessage.remove();
                }, 300);
            }
        }, 2000);
    });
};
