import Swiper from 'swiper';
import { Scrollbar } from 'swiper/modules';

export default function articleSliders() {
    const sliders = document.querySelectorAll('.js-slider-mobile');

    sliders.forEach(el => {
        const slider = new Swiper(el, {
            modules: [Scrollbar],
            slidesPerView: 3,
            spaceBetween: 16,
            breakpoints: {
                960: {
                    slidesPerView: 2,
                },
                578: {
                    slidesPerView: 2
                },
                320: {
                    slidesPerView: 1.3,
                },
            },
            scrollbar: {
                el: '.swiper-scrollbar',
            },
        });
    });
}
